setTimeout(() => {
    dismissToast()
}, 5000);

function dismissToast () {
    let toasts = document.getElementsByClassName('js-toast');
    if (toasts) {
        for (var i = 0; i < toasts.length; i++) {
            toasts[i].classList.add("fadeOutUp")
            setTimeout(() => {
                if (toasts[i]) {
                    toasts[i].remove()
                }
            }, 2000);
        }
        if (document.getElementsByClassName('js-toast-wrapper')[0]) {
            document.getElementsByClassName('js-toast-wrapper')[0].style.display = "none";
        }
    }
}
