import '../../../node_modules/quill/dist/quill';
import Quill from 'quill';

export class QuillEditor {
    start () {
        document.querySelectorAll('.js-quill').forEach(element => {
            window['editor' + '-' + element.id] = new Quill(element, {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline'], // toggled buttons
                        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                        ['link', 'clean'],
                    ]
                },
                theme: 'snow'
            })

            window['editor' + '-' + element.id].on('text-change', function (delta, oldDelta, source) {
                var input = document.getElementById(element.id + '-input');
                input.value = window['editor' + '-' + element.id].root.innerHTML;
            })
        })
    }
}


