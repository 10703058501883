import { Modal } from 'flowbite';

const deleteModalEl = document.getElementById('delete-modal');
const deleteModal = new Modal(deleteModalEl, {
    placement: 'bottom-right',
    backdrop: 'dynamic',
    backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-0',
    closable: true,
});

const deleteButtons = document.getElementsByClassName('js-delete');
for (var i = 0; i < deleteButtons.length; i++) {
    deleteButtons[i].onclick = deleteResource(deleteButtons[i])
};

const cancelDeleteModal = document.getElementsByClassName('js-close-delete-modal');
for (var i = 0; i < cancelDeleteModal.length; i++) {
    cancelDeleteModal[i].addEventListener('click', function handleClick () {
        deleteModal.hide()
    })
};

function deleteResource (el) {
    return function () {
        let form = document.getElementById('delete-modal-form')
        form.action = form.action.replace('#ID#', el.dataset.id)
        deleteModal.show()
    }
};
