import { Tabs } from 'flowbite';

window.candidateVacancyTabs = function () {
    let defaultTab = 'status';

    if (window.location.href.indexOf('?') != -1) {
        let paramString = window.location.href.split('?')[1].split('&');
        paramString.forEach(element => {
            let queryString = new URLSearchParams(element);

            for (let pair of queryString.entries()) {
                if (pair[0] === 'tab' && pair[1] === 'notes') {
                    defaultTab = 'notes';
                }
            }
        });
    }

    const tabElements = [
        {
            id: 'status',
            triggerEl: document.querySelector('#status-tab'),
            targetEl: document.querySelector('#status-content')
        },
        {
            id: 'notes',
            triggerEl: document.querySelector('#notes-tab'),
            targetEl: document.querySelector('#notes-content')
        },
        {
            id: 'files',
            triggerEl: document.querySelector('#files-tab'),
            targetEl: document.querySelector('#files-content')
        },
    ];

    const tabs = new Tabs(tabElements, {
        defaultTabId: defaultTab,
    });
}

window.candidateTabs = function () {
    let defaultTab = 'status';

    if (window.location.href.indexOf('?') != -1) {
        let paramString = window.location.href.split('?')[1].split('&');
        paramString.forEach(element => {
            let queryString = new URLSearchParams(element);

            for (let pair of queryString.entries()) {
                if (pair[0] === 'tab' && pair[1] === 'notes') {
                    defaultTab = 'notes';
                }
            }
        });
    }

    const tabElements = [
        {
            id: 'status',
            triggerEl: document.querySelector('#status-tab'),
            targetEl: document.querySelector('#status-content')
        },
        {
            id: 'vacancies',
            triggerEl: document.querySelector('#vacancies-tab'),
            targetEl: document.querySelector('#vacancies-content')
        },
    ];

    const tabs = new Tabs(tabElements, {
        defaultTabId: defaultTab,
    });
}

window.vacancyTabs = function () {
    let defaultTab = 'candidates';

    if (window.location.href.indexOf('?') != -1) {
        let paramString = window.location.href.split('?')[1].split('&');
        paramString.forEach(element => {
            let queryString = new URLSearchParams(element);

            for (let pair of queryString.entries()) {
                if (pair[0] === 'tab' && pair[1] === 'kanban') {
                    defaultTab = 'kanban';
                }
            }
        });
    }

    const tabElements = [
        {
            id: 'candidates',
            triggerEl: document.querySelector('#candidates-tab'),
            targetEl: document.querySelector('#candidates-content')
        },
        {
            id: 'kanban',
            triggerEl: document.querySelector('#kanban-tab'),
            targetEl: document.querySelector('#kanban-content')
        },
        {
            id: 'files',
            triggerEl: document.querySelector('#files-tab'),
            targetEl: document.querySelector('#files-content')
        },
    ];

    const tabs = new Tabs(tabElements, {
        defaultTabId: defaultTab,
    });
}
