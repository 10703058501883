import Chart from 'chart.js/auto';

Chart.defaults.font.family = "Inter";

/* Chart.plugins.register({
    beforeDraw: function (chartInstance) {
        var ctx = chartInstance.chart.ctx;
        ctx.fillStyle = 'black';
        ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
    }
}) */

window.chart = function (element, data, type = 'default') {
    if ((typeof data.data === 'string' || data.data instanceof String) && data.type != 'number' && type != 'minimal') {
        emptyMessage(element, data)
    } else {
        initChart(element, data, type)
    }
}

const emptyMessage = (element, data) => {
    element.classList.remove('gap-6', 'grid')
    element.classList.add('gap-3')
    element.innerHTML = '<h2 class="mb-0 text-lg font-bold tracking-tight text-gray-900 dark:text-white print:text-base print:font-semibold col-span-full">' + data.title + '</h2>'
    element.innerHTML += '<p class="text-sm text-gray-500 dark:text-gray-400">' + data.data + '</p>'
}

const initChart = (element, data, type) => {
    let defaultColor = '#02f5e1';
    let labels = []
    let yLabels = []
    let values = []
    let colors = []
    let chartCanvas = null

    if (!(element instanceof Element)) {
        element = document.querySelectorAll(element)[0]
    }

    chartCanvas = element.querySelectorAll('.js-chart')[0]

    Object.values(data.data).forEach((item, index) => {
        labels[index] = item.x_label;
        values[index] = item.y;
        if (data.properties.has_colors) {
            colors[index] = item.color !== null ? item.color : defaultColor;
        } else {
            colors = defaultColor;
        }
        yLabels[item.y] = item.y_label;
    });

    setChartTitle(element, data)

    switch (data.type) {
        case 'pie_chart_with_total':
            setCustomLabel(element, values, labels, colors);
            pieChart(chartCanvas.getContext('2d'), values, labels, colors, data.properties.has_total, data.total);
            break;
        case 'bars_vertical':
            barChart(chartCanvas.getContext('2d'), values, labels, colors, yLabels);
            break;
        case 'bars_horizontal':
            barHorizontalChart(chartCanvas.getContext('2d'), values, labels, colors);
            break;
        case 'number':
            numberChart(chartCanvas, data)
            break;
        case 'line_graph':
            lineChart(chartCanvas.getContext('2d'), values, labels, colors, yLabels, type);
            break;
        case 'small_line_graph':
            smallLineChart(chartCanvas.getContext('2d'), values, labels);
            break;
        default:
            console.log(`No chart`);
    }
}

window.setChartTitle = function (element, data) {
    element.querySelectorAll('.js-title').forEach(item => {
        item.innerHTML = data.title
    });
}

window.setCustomLabel = function (element, values, labels, colors) {
    let placeholder = element.querySelectorAll('.js-custom-label')[0];

    Object.values(values).forEach((item, index) => {
        placeholder.innerHTML = placeholder.innerHTML +
            '<div>' +
            '<span class="relative mb-1 block h-1 w-full bg-slate-400" style="background-color: ' + colors[index] + '"></span>' +
            '<p class="text-2xl font-semibold text-slate-400" style="color: ' + colors[index] + '">' + values[index] + '</p>' +
            '<p class="text-xs">' + labels[index] + '</p>' +
            '</div>';
    });
}

// SMALL
window.smallLineChart = function (element, data, labels, colors) {
    const options = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        elements: {
            point: {
                radius: 0
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                scaleLabel: false,
                ticks: {
                    display: false
                }
            },
            y: {
                grid: {
                    display: false,
                },
                scaleLabel: false,
                ticks: {
                    display: false,
                }
            }
        }
    };

    new Chart(element, {
        type: "line",
        data: {
            labels,
            datasets: [
                {
                    fill: true,
                    backgroundColor: "rgba(10, 169, 87, 0.2)",
                    borderColor: "rgba(10, 169, 87, 1)",
                    borderWidth: 0,
                    data,
                    tension: 0.4
                },
            ],
        },
        options
    });
}

// LINE
window.lineChart = function (element, data, labels, colors, yLabels = null, type = 'default') {
    let grad = element.createLinearGradient(0, 0, 0, window.innerHeight)
    grad.addColorStop(0, 'rgba(12, 206, 107, .6)')
    grad.addColorStop(.5, 'rgba(12, 206, 107, .4)')
    grad.addColorStop(1, 'rgba(12, 206, 107, .0)')
    let options = null

    if (type === 'minimal') {
        options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            elements: {
                point: {
                    radius: 0
                },
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    scaleLabel: false,
                    ticks: {
                        display: false
                    }
                },
                y: {
                    grid: {
                        display: false,
                    },
                    scaleLabel: false,
                    ticks: {
                        display: false,
                    }
                }
            }
        };
    } else {
        options = {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
                mode: 'index',
                intersect: false,
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler
                }
            },
            /* elements: {
                point: {
                    radius: 0
                },
            }, */
            scales: {
                x: {
                    grid: {
                        display: true,
                        lines: {
                            color: 'rgba(64, 71, 91, 1)',
                            borderDash: [5, 2]
                        }
                    },
                    scaleLabel: false,
                    ticks: {
                        color: '#64748B',
                    },
                },
                y: {
                    grid: {
                        display: true,
                        lines: {
                            color: 'rgba(64, 71, 91, 1)',
                            borderDash: [5, 2]
                        }
                    },
                    scaleLabel: false,
                    ticks: {
                        color: '#64748B',
                        display: true,
                        suggestedMin: 0,
                        callback: function (value, index, values) {
                            return yLabels ? yLabels[value] : value
                        }
                    }
                }
            }
        };
    }

    new Chart(element, {
        type: "line",
        data: {
            labels,
            datasets: [
                {
                    fill: true,
                    backgroundColor: grad,
                    //backgroundColor: "rgba(10, 169, 87, 0.2)",
                    borderColor: "rgba(10, 169, 87, 1)",
                    borderWidth: 2,
                    data,
                    tension: 0.4
                },
            ],
        },
        options
    });
}

// BAR VERTICAL
window.barChart = function (element, data, labels, colors, yLabels = null) {
    new Chart(element, {
        type: 'bar',
        data: {
            labels,
            datasets: [{
                data,
                borderWidth: 0,
                backgroundColor: colors,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                easing: 'easeInOutQuad',
                duration: 0
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        color: '#64748B',
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        display: true,
                    },
                    ticks: {
                        stepSize: 100,
                        beginAtZero: true,
                        color: '#64748B',
                        callback: function (value, index, values) {
                            return toHoursAndMinutes(value)
                        }
                    }
                }
            },
            point: {
                backgroundColor: 'white'
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: function (context) {
                        externalTooltipHandler(context, yLabels)
                    }
                }
            }
        }
    });
}

// BAR HORIZONTAL
window.barHorizontalChart = function (element, data, labels, colors, yLabels = null) {
    new Chart(element, {
        type: 'bar',
        data: {
            labels,
            datasets: [{
                data,
                borderWidth: 0,
                backgroundColor: colors,
            }]
        },
        options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            animation: {
                easing: 'easeInOutQuad',
                duration: 2000
            },
            scales: {
                x: {
                    grid: {
                        display: false,
                    },
                    ticks: {
                        stepSize: 1,
                        color: '#64748B',
                    }
                },
                y: {
                    beginAtZero: true,
                    grid: {
                        display: true,
                    },
                    ticks: {
                        color: '#64748B',
                    }
                }
            },
            point: {
                backgroundColor: 'white'
            },
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler,
                }
            }
        }
    });
}

// PIE
window.pieChart = function (element, data, labels, colors, has_total = false, total = 0) {
    const innerLabel = {
        id: 'innerLabel',
        afterDatasetDraw (chart, args, pluginOptions) {
            const { ctx } = chart;
            const meta = args.meta;
            const xCoor = meta.data[0].x;
            const yCoor = meta.data[0].y + 10;
            //const perc = chart.data.datasets[0].data[0] / meta.total * 100;
            const perc = total;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.font = '32px "Inter"';
            ctx.fillStyle = 'rgb(110 120 147)';
            ctx.fillText(perc, xCoor, yCoor);
            ctx.restore();
        },
    };

    new Chart(element, {
        type: 'doughnut',
        plugins: [innerLabel],
        data: {
            labels,
            datasets: [{
                data,
                borderWidth: 5,
                backgroundColor: colors,
            }]
        },
        options: {
            responsive: true,
            maintainAspectRatio: true,
            cutout: '30%',
            animation: {
                easing: 'easeInOutQuad',
                duration: 500
            },
            plugins: {
                legend: {
                    display: false,
                    position: 'right',
                    align: 'center',
                    labels: {
                        boxWidth: '8',
                        boxHeight: '8',
                        usePointStyle: true,
                        pointStyle: 'circle',
                    }
                },
                tooltip: {
                    enabled: false,
                    position: 'nearest',
                    external: externalTooltipHandler
                }
            },
        }
    });
}

// NUMBER
window.numberChart = function (element, data) {
    element.innerHTML = data.data
}

const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.classList.add('shadow-xl', '!px-4', '!py-2', 'bg-white', 'w-max', 'dark:bg-gray-900', 'rounded-xl', 'font-sans', 'border', 'border-slate-200', 'dark:border-gray-700');
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
};

const externalTooltipHandler = (context, customLabels = null) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
    }

    // Set Text
    if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        if (customLabels) {
            for (let index = 0; index < bodyLines.length; index++) {
                let key = bodyLines[index][0].replace(/\s/g, '')
                bodyLines[index] = customLabels[key];
            }
        }

        const tableHead = document.createElement('thead');

        titleLines.forEach(title => {
            const tr = document.createElement('tr');
            tr.style.borderWidth = 0;

            const th = document.createElement('th');
            th.style.borderWidth = 0;
            const text = document.createTextNode(title);

            th.appendChild(text);
            tr.appendChild(th);
            tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;
            span.style.borderColor = colors.borderColor;
            span.style.borderWidth = '0px';
            span.style.marginTop = '10px';
            span.style.marginRight = '10px';
            span.style.height = '13px';
            span.style.width = '13px';
            span.style.display = 'inline-block';
            span.classList.add('rounded-full');

            const tr = document.createElement('tr');
            tr.style.backgroundColor = 'inherit';
            tr.style.borderWidth = 0;

            const td = document.createElement('td');
            td.style.borderWidth = 0;

            const text = document.createTextNode(body);

            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
};

const toHoursAndMinutes = (d) => {
    /* const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return minutes; */
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    /*   var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " m " : " m ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : ""; */
    return (h < 1) ? m + 'm' : h + 'h' + m + 'm';
}
