import { Dropdown } from 'flowbite';

const $targetEl = document.getElementById('actions-menu');
const $triggerEl = document.getElementById('actions-menu-trigger');
const $panelEl = document.getElementById('actions-menu-panel');
const $iconEl = document.getElementById('actions-menu-icon');

// options with default values
const dropdown = new Dropdown($targetEl, $triggerEl, {
    placement: 'left-start',
    triggerType: 'click',
    offsetSkidding: 0,
    offsetDistance: 10,
    delay: 300,
    ignoreClickOutsideClass: false,
    onHide: () => {
        $panelEl.classList.toggle('hidden');
        $iconEl.classList.toggle('rotate-45');
    },
    onShow: () => {
        $panelEl.classList.toggle('hidden');
        $iconEl.classList.toggle('rotate-45');
    }
});
