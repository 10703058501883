
class Kanban {
    constructor(getUrl, postUrl, callback) {
        this.getUrl = getUrl
        this.postUrl = postUrl
        this.callback = callback
        this.element = '#kanban'
        this.init()
    }

    init () {
        this.getData().then((response) => this.populate(response));
    }

    async getData () {
        let response = await axios.get(this.getUrl);
        return response.data;
    }

    populate (data) {
        let _this = this

        new jKanban({
            element: this.element,
            gutter: '15px',
            widthBoard: '250px',
            dragItems: true,
            boards: data,
            dragBoards: false,
            itemHandleOptions: {
                enabled: false,
                handleClass: "item_handle",
                customCssHandler: "drag_handler",
                customHandler: "<span class='item_handle'>+</span> %s",
            },
            dropEl: function (el, target, source, sibling) {
                _this.postData({
                    candidate_id: el.dataset.eid,
                    phase_id: target.parentElement.dataset.id,
                })
            },
            click: function (el) { },
            dragEl: function (el, source) { },
            dragendEl: function (el) { },
            dragBoard: function (el, source) { },
            dragendBoard: function (el) { },
            buttonClick: function (el, boardId) { }
        })
    }

    postData (data, useCallback = true) {
        axios.post(this.postUrl, data).then(response => {
            if (useCallback && this.callback !== undefined) {
                this.callback(data)
            }
        });
    }
}

window.Kanban = Kanban

