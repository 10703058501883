import 'flowbite';
import './bootstrap';
import './components/theme-toggle';
import './components/action-button';
import './components/delete-modal';
import './components/delete-notes';
import './components/toasts';
import './components/kanban';
import './components/tabs';
import './components/tabs';
import './components/charts';
import { QuillEditor } from './components/quill';
import Alpine from 'alpinejs'
import Choices from 'choices.js'
import * as Sentry from "@sentry/browser";

window.quill = new QuillEditor()
quill.start()

window.Choices = Choices;

document.querySelectorAll('.js-choices').forEach((element) => {
    const choices = new Choices(element, {
        removeItems: true,
        removeItemButton: true
    })
})

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: import.meta.env.VITE_SENTRY_RELEASE + "@2.3.12",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
    })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,

    environment: import.meta.env.MODE,
});
